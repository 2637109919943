import { createStore } from 'vuex'
import user from './modules/user'
import role from './modules/role'
import region from './modules/region'
import order from './modules/order'
import transaction from './modules/transaction'
import subscription from './modules/subscription'

export default createStore({
  modules: {
    user,
    role,
    region,
    order,
    transaction,
    subscription,
  }
})
