<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>
.error{
  color: red;
}
</style>
