/* eslint-disable*/
const state = ()=> ({
    customerWalletHistory: {},
});

const getters = {

    retrieveCustomerWalletHistory(state, getters, rootState){
        if (state.customerWalletHistory==null) return null;
        return state.customerWalletHistory;
    },
};

const mutations = {

    saveCustomerWalletHistory(state, customerWalletHistory) {
        if (Object.keys(state.customerWalletHistory).length === 0){
            state.customerWalletHistory = customerWalletHistory;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.customerWalletHistory.history));
            state.customerWalletHistory.history = formattedData.concat(customerWalletHistory.history);
        }
    },
    clearCustomerWalletHistory(state) {
        state.customerWalletHistory = {};
    },

    
};

const actions = {

    saveCustomerWalletHistory({commit}, customerWalletHistory) {
        commit('saveCustomerWalletHistory', customerWalletHistory);
    },
    
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}