// this file should format it's response to

/*
{
    success: true or false
    data: "information"
}
*/

import network from './network'
import network2 from './network2'
import routes from './apiRoutes'

const internet_connection_message = 'There was a problem connection. Check your network connection...'
const problem_message = 'A problem occured. Please try again later'

const api = {


  async adminLogin (email, password) {
    const returnValue = new Object()
    const data = {
      login_method: 'email_and_password',
      email: email,
      password: password,
      device_id: `web-${email}`
    }
    const result = await network.post(routes.admin.login(), data).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:
          returnValue.success = true
          returnValue.data = result.data.data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  async getAdminRoles () {
    const returnValue = new Object()
    const result = await network.get(routes.admin.adminRoles()).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:
          returnValue.success = true
          returnValue.data = result.data.data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  async getCountryData (pageNumber) {
    const returnValue = {}
    const result = await network.get(routes.getCountryData(pageNumber)).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:

          returnValue.success = true
          returnValue.data = result.data.data
          returnValue.extraData = result.data.next_page_url
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  // async getPriceListForCity () {
  //   const returnValue = {}
  //   const result = await network.get(routes.admin.getPriceListForCity()).then((response) => response).catch((error) => error)
  // },

  async searchUser (data, perpage, page) {
    const returnValue = {}
    const result = await network.get(routes.admin.admins(perpage, page, data)).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:

          returnValue.success = true
          returnValue.data = result.data.data
          returnValue.extra_data = result.data.extra_data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  async createNewAdmin (adminData) {
    const returnValue = new Object()
    const data = {
      registration_method: 'email_and_password',
      email: adminData.email,
      first_name: adminData.first_name,
      last_name: adminData.last_name,
      phone: adminData.phone,
      role_id: adminData.role_id,
      nomenclature_id: adminData.nomenclatureID,
      action_type: adminData.riderType,
    }
    const result = await network.post(routes.admin.newAdmin(), data).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:
          returnValue.success = true
          returnValue.data = result.data.data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  async resetPassword (data) {
    const returnValue = {}
    const result = await network.get(routes.admin.resetPassword(data.email)).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:
          returnValue.success = true
          returnValue.data = result.data.data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  async completeRegistration (completionData) {
    const returnValue = {}
    const data = {
      confirmation_token: completionData.token,
      password: completionData.password,
      device_id: completionData.device_id,
    }
    const result = await network.post(routes.admin.completeRegistration(), data).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:
          returnValue.success = true
          returnValue.data = result.data.data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  async completePasswordReset (completionData) {
    const returnValue = {}
    const data = {
      confirmation_token: completionData.token,
      password: completionData.password
    }
    console.log("password reset data is ", data)
    const result = await network.post(routes.admin.completePasswordReset(), data).then((response) => response).catch((error) => error)
    if (!result.response && !result.status) {
      returnValue.success = false
      returnValue.data = internet_connection_message
      return returnValue
    }
    if (result.status) {
      switch (result.status) {
        case 200:
          returnValue.success = true
          returnValue.data = result.data.data
          break
      }
      return returnValue
    }
    if (!result.status) {
      switch (result.response.status) {
        case 400:
          returnValue.success = false
          returnValue.data = result.response.data.data
          break
        default:
          returnValue.success = false
          returnValue.data = problem_message
      }
      return returnValue
    }
  },

  user: {
    async requestExport(searchData) {
      if (!searchData) searchData={};
      const result = await network.get(routes.admin.requestAdminExport(searchData.email, searchData.name, searchData.role_id)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async searchActivityLogs(data, perpage, page) {
      const result = await network.get(routes.admin.searchActivityLogs(data, perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadAdminProfile() {
      const result = await network.get(routes.admin.profile()).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchAdminData(userID) {
      const result = await network.get(routes.admin.admin(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateProfile(adminData) {
      if (!adminData) return;
      const data = {
        "email": adminData.email,
        "phone": adminData.phone,
        "first_name": adminData.firstName,
        "last_name": adminData.lastName,
      }
      console.log("data in api for update profile is ", data)
      const result = await network.post(routes.admin.updateProfile(), data).then((response) => response).catch((error) => error)
      console.log("api response result for update data is ", result)
      return this.apiResponseFormat(result);
    },
    async updateAdminData (adminData) {
      const data = {
        user_id: adminData.userID,
        email: adminData.email,
        first_name: adminData.first_name,
        last_name: adminData.last_name,
        phone: adminData.phone,
      }
      const result = await network.post(routes.admin.updateAdminData(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateRiderRegion (adminData) {
      const data = {
        user_id: adminData.userID,
        nomenclature_id: adminData.nomenclatureID,
        action_type: adminData.riderType,
      }
      const result = await network.post(routes.admin.updateRiderRegion(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async logoutUser(userID) {
      const result = await network.get(routes.admin.logout(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    apiResponseFormat(result) {
      if (result==null) throw('Api result cannot be null');
  
      let returnValue = {};
  
      if (!result.response && !result.status) {
        returnValue.success = false
        returnValue.data = internet_connection_message
        return returnValue
      }
      if (result.status) {
        switch (result.status) {
          case 200:
            returnValue.success = true
            returnValue.data = result.data.data
            break
        }
        return returnValue
      }
      if (!result.status) {
        switch (result.response.status) {
          case 400:
            returnValue.success = false
            returnValue.data = result.response.data.data
            break
          default:
            returnValue.success = false
            returnValue.data = problem_message
        }
        return returnValue
      }
    }
  },

  order: {
    async requestExport(searchData) {
      if (!searchData) searchData={};
      const result = await network.get(routes.admin.requestOrderExport(searchData)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async loadStatistics(orderStatus){
      const result = await network.get(routes.admin.loadOrderStatistics(orderStatus)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadCustomerOrderStatistics(userID, orderStatus){
      const result = await network.get(routes.admin.loadCustomerOrderStatistics(userID, orderStatus)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadOrder(orderID) {
      const result = await network.get(routes.admin.loadOrder(orderID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },


    async loadOrders(orderData, perpage, page) {

        let data = {}
        if (orderData!=null){
          data = {
              "orderID": orderData.orderID ? orderData.orderID : '',
              "status": orderData.status ? orderData.status : '',
              "userID": orderData.userID ? orderData.userID : '',
              "name": orderData.name ? orderData.name : '',
              "email": orderData.email ? orderData.email : '',
              "phone": orderData.phone ? orderData.phone : '',
              "from_date": orderData.from_date ? orderData.from_date : '',
              "to_date": orderData.to_date ? orderData.to_date : '',
          }
        }
        const result = await network.get(routes.admin.loadOrders(data, perpage, page)).then((response) => response).catch((error) => error)
        return this.apiResponseFormat(result);
    },

    async loadSpecificCustomerOrder(orderData, perpage, page) {
      let data = {}
      if (orderData==null){
        throw('user id required');
      }
      data = {
        "user_id": orderData.userID,
        "orderID": orderData.orderID ? orderData.orderID : null,
        "status": orderData.status ? orderData.status : null,
      }
      console.log("sending specific customer in api, data is ", data)
      
      const result = await network.post(routes.admin.loadSpecificCustomerOrder(perpage, page), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async uploadExcel(excelFile) {
      var formData = new FormData();
      formData.append("excel_file", excelFile);
      formData.append("name", "omeone");
      console.log('using network 2 to post');
      console.log("formdata is ", formData)
      const result = await network2.post(routes.admin.uploadExcelFile(), formData).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result) 
    },

    async createNewOrder (orderData) {
      console.log("api order data is ", orderData)
      // let excelPath = "";
      // if (orderData.excelFile){
      //   let response = this.uploadExcel(orderData.excelFile);
      //   if (!response.success) {
      //     return response;
      //   }
      //   excelPath = response.data
      // }
      
      // const deliveryDetails = [];
      // for(let detail of orderData.deliveryDetails){
      //   const aa = {
      //     "delivery_name":detail.deliveryName,
      //     "delivery_address":detail.deliveryAddress,
      //     "delivery_city_id":detail.deliveryCityID,
      //     "delivery_state_id":detail.deliveryStateID,
      //     "delivery_country_id":detail.deliveryCountryID,
      //     "delivery_phone":detail.deliveryPhone,
      //     "delivery_email":detail.deliveryEmail,
      //   }
      //   deliveryDetails.push(aa)
      // }
      // const data = {
      //   "user_id":orderData.userID,
      //   "pickup_name":orderData.pickupName,
      //   "pickup_address":orderData.pickupAddress,
      //   "pickup_city_id":orderData.pickupCityID,
      //   "pickup_state_id":orderData.pickupStateID,
      //   "pickup_country_id":orderData.pickupCountryID,
      //   "pickup_phone":orderData.pickupPhone,
      //   "pickup_email":orderData.pickupEmail,
      //   "item_description":orderData.itemDescription,
      //   "delivery_details": deliveryDetails,
      //   "excel_path":excelPath,
      //   "use_subscription":orderData.useSubscription,
      //   "user_subscription_location_id":orderData.subscriptionAddressID,
        
      // }
      // console.log("in api data is ", data)
      let formData = new FormData();
      formData.append('user_id', orderData.userID);
      formData.append('pickup_name', orderData.pickupName);
      formData.append('pickup_address', orderData.pickupAddress);
      formData.append('pickup_city_id', orderData.pickupCityID);
      formData.append('pickup_state_id', orderData.pickupStateID);
      formData.append('pickup_country_id', orderData.pickupCountryID);
      formData.append('pickup_phone', orderData.pickupPhone);
      formData.append('pickup_email', orderData.pickupEmail);
      formData.append('item_description', orderData.itemDescription);
      formData.append('delivery_details', orderData.deliveryDetails);
      formData.append('excel_file', orderData.excelFile);
      formData.append('use_subscription', orderData.useSubscription);
      formData.append('user_subscription_location_id', orderData.subscriptionAddressID);

      for (let i = 0; i < orderData.deliveryDetails.length; i++) {
        for (let key of Object.keys(orderData.deliveryDetails[i])) {
          console.log('hello...', orderData.deliveryDetails[i][key]);
          formData.append(`delivery_details[${i}][delivery_${key}]`, orderData.deliveryDetails[i][key]);
        }
      }

      console.log('form data is ', formData);

      const result = await network2.post(routes.admin.newOrder(), formData).then((response) => response).catch((error) => error)
      // const result = await network.post(routes.admin.newOrder(), data).then((response) => response).catch((error) => error)
      console.log("response of order from api class  ", result)
      return this.apiResponseFormat(result)
    },

    async confirmOrder (orderData) {
      const data = {
        "orderID": orderData.orderID,
        "user_id":orderData.userID,
        "status": orderData.status,
        "status_comment": orderData.statusComment,
        "item_weight_id": orderData.weightID,
        "delivery_cost": orderData.deliveryCost,
        "delivery_extra_charges": orderData.extraCharge,
        "delivery_extra_charges_comment": orderData.extraChargeComment,
        "paid_by": orderData.paidBy,
        "payment_method": orderData.paymentMethod,
        "payment_reference": orderData.paymentReference,
      }
      const result = await network.post(routes.admin.confirmOrder(), data).then((response) => response).catch((error) => error)
      console.log("response of order from api class  ", result)
      return this.apiResponseFormat(result)
    },

    async updateOrder (orderData) {
      const data = {
        "orderID": orderData.orderID,
        "user_id":orderData.userID,
        "status": orderData.status,
        "status_comment": orderData.statusComment,
        "item_weight_id": orderData.weightID,
        "delivery_cost": orderData.deliveryCost,
        "delivery_extra_charges": orderData.extraCharge,
        "delivery_extra_charges_comment": orderData.extraChargeComment,
        "paid_by": orderData.paidBy,
        "payment_method": orderData.paymentMethod,
        "payment_reference": orderData.paymentReference,
        "cancellation_charge": orderData.cancellationCharge,
        "pickup_rider_user_id": orderData.pickupRiderUserId,
        "delivery_rider_user_id": orderData.deliveryRiderUserId,
      }
      console.log("in update order data is ", data)
      const result = await network.post(routes.admin.updateOrder(), data).then((response) => response).catch((error) => error)
      console.log("response of order from api class  ", result)
      return this.apiResponseFormat(result)
    },

    async updateBatchOrder (orderData) {
      const data = {
        batch_orderIDs: orderData.selectedOrders,
        status: orderData.status,
      }
      const result = await network.post(routes.admin.updateBatchOrder(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result)
    },



    async loadRiderOrderStatistics(userID, orderStatus){
      const result = await network.get(routes.admin.loadRiderOrderStatistics(userID, orderStatus)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async loadSpecificRiderOrder(orderData, perpage, page) {
      let data = {}
      if (orderData==null){
        throw('user id required');
      }
      data = {
        "user_id": orderData.userID,
        "orderID": orderData.orderID ? orderData.orderID : null,
        "status": orderData.status ? orderData.status : null,
      }
      console.log("sending specific rider in api, data is ", data)
      
      const result = await network.post(routes.admin.loadSpecificRiderOrder(perpage, page), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    apiResponseFormat(result) {
      if (result==null) throw('Api result cannot be null');
  
      let returnValue = {};
  
      if (!result.response && !result.status) {
        returnValue.success = false
        returnValue.data = internet_connection_message
        return returnValue
      }
      if (result.status) {
        switch (result.status) {
          case 200:
            returnValue.success = true
            returnValue.data = result.data.data
            returnValue.extra_data = result.data.extra_data
            break
        }
        return returnValue
      }
      if (!result.status) {
        switch (result.response.status) {
          case 400:
            returnValue.success = false
            returnValue.data = result.response.data.data
            break
          default:
            returnValue.success = false
            returnValue.data = problem_message
        }
        return returnValue
      }
    }
  },


  customer: {
    async requestExport(searchData) {
      if (!searchData) searchData={};
      const result = await network.get(routes.admin.requestExport(searchData.email, searchData.name, searchData.phone)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchCustomers(searchData, perpage, page) {
      if (!searchData) searchData={};
      const result = await network.get(routes.admin.customers(searchData.email, searchData.name, searchData.phone, perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchCustomerData(userID) {
      const result = await network.get(routes.admin.customer(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchCustomerApiAccess(userID) {
      const result = await network.get(routes.admin.customerApiAccess(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async activateApi(userID) {
      const result = await network.get(routes.admin.activateApi(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async deactivateApi(userID) {
      const result = await network.get(routes.admin.deactivateApi(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateCustomerAPIAccess (customerData) {
      const data = {
        user_id: customerData.userID,
        user_subscription_location_id: customerData.locationID,
      }
      const result = await network.post(routes.admin.updateCustomerAPIAccess(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async createNewCustomer (customerData) {
      const data = {
        registration_method: 'email_and_password',
        account_type: customerData.customerType,
        email: customerData.email,
        first_name: customerData.first_name,
        last_name: customerData.last_name,
        business_name: customerData.business_name,
        phone: customerData.phone,
      }
      console.log("customer data in api is ", data)
      const result = await network.post(routes.admin.newCustomer(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateCustomerData (customerData) {
      const data = {
        user_id: customerData.userID,
        email: customerData.email,
        first_name: customerData.first_name,
        last_name: customerData.last_name,
        business_name: customerData.business_name,
        phone: customerData.phone,
      }
      const result = await network.post(routes.admin.updateCustomerData(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    apiResponseFormat(result) {
      if (result==null) throw('Api result cannot be null');
  
      let returnValue = {};
  
      if (!result.response && !result.status) {
        returnValue.success = false
        returnValue.data = internet_connection_message
        return returnValue
      }
      if (result.status) {
        switch (result.status) {
          case 200:
            returnValue.success = true
            returnValue.data = result.data.data
            returnValue.extra_data = result.data.extra_data
            break
        }
        return returnValue
      }
      if (!result.status) {
        switch (result.response.status) {
          case 400:
            returnValue.success = false
            returnValue.data = result.response.data.data
            break
          default:
            returnValue.success = false
            returnValue.data = problem_message
        }
        return returnValue
      }
    }
  },





  regions: {

    async createNewCountry(countryData){
      if (countryData==null) throw('Invalid country data');
      const data = {
        "name": countryData.name,
      };
      const result = await network.post(routes.admin.createNewCountry(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async createNewState(stateData){
      if (stateData==null) throw('Invalid stateData data');
      const data = {
        "name": stateData.name,
        "country_id":stateData.countryID,
      };
      const result = await network.post(routes.admin.createNewState(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async createNewCity(cityData){
      if (cityData==null) throw('Invalid city data');
      const data = {
        "name": cityData.name,
        "state_id": cityData.stateID,
      };
      const result = await network.post(routes.admin.createNewCity(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },


    async fetchCountries(searchData={}, type=null, nomenclatureID=null, subscriptionID=null){
      if (!searchData) searchData = {};
      const data = {
        query: searchData.query ? searchData.query : '',
      }
      const result = await network.get(routes.getCountries(data, type, nomenclatureID, subscriptionID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchStates(searchData={}, type=null, nomenclatureID=null, subscriptionID=null){
      if (!searchData) searchData = {};
      const data = {
        countryID: searchData.countryID ? searchData.countryID : '',
        query: searchData.query ? searchData.query : '',
      }
      const result = await network.get(routes.getStates(data, type, nomenclatureID, subscriptionID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchCities(searchData={}, type=null, nomenclatureID=null, subscriptionID=null){
      if (!searchData) searchData = {};
      const data = {
        stateID: searchData.stateID ? searchData.stateID : '',
        query: searchData.query ? searchData.query : '',
      }
      const result = await network.get(routes.getCities(data, type, nomenclatureID, subscriptionID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async queryPriceData(locationData){
      const data = {
        from:{
            "country_id": locationData.from.countryID,
            "state_id": locationData.from.stateID,
            "city_id": locationData.from.cityID,
        },
        to:{
            "country_id": locationData.to.countryID,
            "state_id": locationData.to.stateID,
            "city_id": locationData.to.cityID,
        }
    };
    console.log("sending to request ", data);
    const result = await network.post(routes.getPriceEstimate(), data).then((response) => response).catch((error) => error)
    console.log("result for price is ", result)
    return this.apiResponseFormat(result);
    },
    async loadNomenclatureList(bound, perpage, page){
      const result = await network.get(routes.admin.nomenclature(bound, perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async loadInterCityNomenclatureList(perpage, page){
      const result = await network.get(routes.admin.interCityNomenclature(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async loadInterStateNomenclatureList(perpage, page){
      const result = await network.get(routes.admin.interStateNomenclature(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async loadInterCountryNomenclatureList(perpage, page){
      const result = await network.get(routes.admin.interCountryNomenclature(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async createNewInterCityNomenclature(nomenclatureData){
      if (nomenclatureData==null) throw('Invalid nomenclature data');
      const data = {
        "name": nomenclatureData.name,
        "country_id": nomenclatureData.countryID,
        "state_id": nomenclatureData.stateID,
        "cities": nomenclatureData.cityList,
      };
      const result = await network.post(routes.admin.createNewInterCityNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateInterCityNomenclature(nomenclatureData){
      if (nomenclatureData==null) throw('Invalid nomenclature data');
      const data = {
        "name": nomenclatureData.name,
        "nomenclature_id": nomenclatureData.nomenclatureID,
        "cities": nomenclatureData.cityList,
      };
      const result = await network.post(routes.admin.updateInterCityNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async createNewInterStateNomenclature(nomenclatureData){
      if (nomenclatureData==null) throw('Invalid nomenclature data');
      const data = {
        "name": nomenclatureData.name,
        "country_id": nomenclatureData.countryID,
        "states": nomenclatureData.stateList,
      };
      const result = await network.post(routes.admin.createNewInterStateNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateInterStateNomenclature(nomenclatureData){
      if (nomenclatureData==null) throw('Invalid nomenclature data');
      const data = {
        "name": nomenclatureData.name,
        "nomenclature_id": nomenclatureData.nomenclatureID,
        "states": nomenclatureData.stateList,
      };
      const result = await network.post(routes.admin.updateInterStateNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async createNewInterCountryNomenclature(nomenclatureData){
      if (nomenclatureData==null) throw('Invalid nomenclature data');
      const data = {
        "name": nomenclatureData.name,
        "countries": nomenclatureData.countryList,
      };
      const result = await network.post(routes.admin.createNewInterCountryNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateInterCountryNomenclature(nomenclatureData){
      if (nomenclatureData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature_id": nomenclatureData.nomenclatureID,
        "name": nomenclatureData.name,
        "countries": nomenclatureData.countryList,
      };
      const result = await network.post(routes.admin.updateInterCountryNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async createNewWeight (weightData) {
      const data = {
        weight: weightData.weight,
      }
      const result = await network.post(routes.admin.newWeight(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result)
    },
    async loadWeightList(perpage, page){
      const result = await network.get(routes.getWeights(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },






    async createNewInterCityPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };
      const result = await network.post(routes.admin.createNewInterCityPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async createNewInterStatePriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };
      const result = await network.post(routes.admin.createNewInterStatePriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async createNewInterCountryPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };
      const result = await network.post(routes.admin.createNewInterCountryPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },



    async updateInterCityPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };

      const result = await network.post(routes.admin.updateInterCityPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async updateInterStatePriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };

      const result = await network.post(routes.admin.updateInterStatePriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async updateInterCountryPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };

      const result = await network.post(routes.admin.updateInterCountryPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async updateInterCountryPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
        "weight_id_and_price_maps": priceListData.weightIDAndPriceMap,
      };

      const result = await network.post(routes.admin.updateInterCountryPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadInterCityPriceList(perpage, page){
      const result = await network.get(routes.admin.interCityPrices(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadInterStatePriceList(perpage, page){
      const result = await network.get(routes.admin.interStatePrices(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadInterCountryPriceList(perpage, page){
      const result = await network.get(routes.admin.interCountryPrices(perpage, page)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },





    async setCountryPickup(_data){
      const data = {
        "pickupCountries": _data.pickupCountries,
      }
      const result = await network.post(routes.admin.setCountryPickup(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async setCountryDelivery(_data){
      const data = {
        "deliveryCountries": _data.deliveryCountries,
      }
      const result = await network.post(routes.admin.setCountryDelivery(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async setStatePickup(_data){
      const data = {
        "pickupStates": _data.pickupStates,
        "country_id": _data.country_id,
      }
      const result = await network.post(routes.admin.setStatePickup(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async setStateDelivery(_data){
      const data = {
        "deliveryStates": _data.deliveryStates,
        "country_id": _data.country_id,
      }
      const result = await network.post(routes.admin.setStateDelivery(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async setCityPickup(_data){
      const data = {
        "pickupCities": _data.pickupCities,
        "state_id": _data.state_id,
      }
      const result = await network.post(routes.admin.setCityPickup(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async setCityDelivery(_data){
      const data = {
        "deliveryCities": _data.deliveryCities,
        "state_id": _data.state_id,
      }
      const result = await network.post(routes.admin.setCityDelivery(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },



    async deleteCountry(countryID){
      const data = {
        "country_id": countryID,
      }
      const result = await network.post(routes.admin.deleteCountry(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async deleteState(stateID){
      const data = {
        "state_id": stateID,
      }
      const result = await network.post(routes.admin.deleteState(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async deleteCity(cityID){
      const data = {
        "city_id": cityID,
      }
      const result = await network.post(routes.admin.deleteCity(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    







    async deleteInterCityPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
      };
      const result = await network.post(routes.admin.deleteInterCityPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async deleteInterStatePriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
      };
      const result = await network.post(routes.admin.deleteInterStatePriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async deleteInterCountryPriceList(priceListData){
      if (priceListData==null) throw('Invalid nomenclature data');
      const data = {
        "nomenclature1_id": priceListData.nomenclature1ID,
        "nomenclature2_id": priceListData.nomenclature2ID,
      };
      const result = await network.post(routes.admin.deleteInterCountryPriceList(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },



    async deleteWeight(weightID){
      const data = {
        "weight_id": weightID,
      };
      const result = await network.post(routes.admin.deleteWeight(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },


    async deleteNomenclature(nomenclatureID){
      const data = {
        "nomenclature_id": nomenclatureID,
      };
      const result = await network.post(routes.admin.deleteNomenclature(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },





    apiResponseFormat(result) {
      if (result==null) throw('Api result cannot be null');
  
      let returnValue = {};
  
      if (!result.response && !result.status) {
        returnValue.success = false
        returnValue.data = internet_connection_message
        return returnValue
      }
      if (result.status) {
        switch (result.status) {
          case 200:
            returnValue.success = true
            returnValue.data = result.data.data
            break
        }
        return returnValue
      }
      if (!result.status) {
        switch (result.response.status) {
          case 400:
            returnValue.success = false
            returnValue.data = result.response.data.data
            break
          default:
            returnValue.success = false
            returnValue.data = problem_message
        }
        return returnValue
      }
    }
  },

  subscription: {
    async createNewSubscription (subscriptionData) {
      const data = {
        "name": subscriptionData.subscriptionName,
        "amount": subscriptionData.subscriptionAmount,
        "order_limit": subscriptionData.orderLimit,
        "validity": subscriptionData.validity,
        "pickup_location_type": subscriptionData.pickupLocationType,
        "destination_type": subscriptionData.destinationType,
        "destination_placeList": subscriptionData.destinationPlaceList,
        "discount": subscriptionData.discount,
        "features": subscriptionData.features,
      }
      const result = await network.post(routes.admin.createSubscription(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async updateSubscription (subscriptionData) {
      const data = {
        "subscription_id": subscriptionData.subscriptionID,
        "name": subscriptionData.subscriptionName,
        "amount": subscriptionData.subscriptionAmount,
        "order_limit": subscriptionData.orderLimit,
        "validity": subscriptionData.validity,
        "pickup_location_type": subscriptionData.pickupLocationType,
        "destination_type": subscriptionData.destinationType,
        "destination_placeList": subscriptionData.destinationPlaceList,
        "discount": subscriptionData.discount,
        "features": subscriptionData.features,
      }
      const result = await network.post(routes.admin.updateSubscription(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async fetchSubscriptions() {
      const result = await network.get(routes.admin.subscriptions()).then((response) => response).catch((error) => error)
      console.log("result from fetch sub is ", result);
      return this.apiResponseFormat(result);
    },

    async fetchSubscription(subscriptionID) {
      const result = await network.get(routes.admin.subscription(subscriptionID)).then((response) => response).catch((error) => error)
      console.log("result from fetch sub is ", result);
      return this.apiResponseFormat(result);
    },

    async loadCustomerSubscriptions(subscriptionData, perpage, page) {
      let data = {
        "user_id": subscriptionData.userID,
        "type_id": subscriptionData.typeID,
      }
      
      const result = await network.post(routes.admin.loadCustomerSubscriptions(perpage, page), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadSubscribedCustomers(subscriptionData, perpage, page) {
      let data = {
        "subscription_id": subscriptionData.subscriptionID,
        "email": subscriptionData.email,
        "name": subscriptionData.name,
      }
      
      const result = await network.post(routes.admin.loadSubscribedCustomers(perpage, page), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadCustomerSubscriptionInformation(userID){
      const result = await network.get(routes.admin.loadCustomerSubscriptionInformation(userID)).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async loadSubscriptionHistory(subscriptionData, perpage, page) {
      let data = {
        "subscription_id": subscriptionData.subscriptionID,
        "email": subscriptionData.email,
        "name": subscriptionData.name,
      }
      
      const result = await network.post(routes.admin.loadSubscriptionHistory(perpage, page), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },
    async subscribeUser(subscriptionData) {
      const data = {
        "user_id": subscriptionData.userID,
        "subscription_id": subscriptionData.subscriptionID,
        "location": subscriptionData.locations,
        "payment_method": subscriptionData.paymentMethod,
      }
      console.log("data in api is", data);
      const result = await network.post(routes.admin.subscribeUser(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async renewCustomerSubscriptions(subscriptionData) {
      const data = {
        "user_id": subscriptionData.userID,
        "payment_method": subscriptionData.paymentMethod,
      }
      console.log("data in api is", data);
      const result = await network.post(routes.admin.renewCustomerSubscriptions(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },



    apiResponseFormat(result) {
      if (result==null) throw('Api result cannot be null');
  
      let returnValue = {};
  
      if (!result.response && !result.status) {
        returnValue.success = false
        returnValue.data = internet_connection_message
        return returnValue
      }
      if (result.status) {
        switch (result.status) {
          case 200:
            returnValue.success = true
            returnValue.data = result.data.data
            break
        }
        return returnValue
      }
      if (!result.status) {
        switch (result.response.status) {
          case 400:
            returnValue.success = false
            returnValue.data = result.response.data.data
            break
          default:
            returnValue.success = false
            returnValue.data = problem_message
        }
        return returnValue
      }
    }
  },

  transaction: {
    async loadCustomerWalletHistory(customerWalletData, perpage, page) {
      let data = {}
      if (customerWalletData!=null){
        data = {
          "type": customerWalletData.type ? customerWalletData.type : null,
          "transactionID": customerWalletData.transactionID ? customerWalletData.transactionID : null,
          "user_id": customerWalletData.userID ? customerWalletData.userID : null,
        }
      }
      const result = await network.post(routes.admin.loadCustomerWalletHistory(perpage, page), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async topupWallet(topupData) {
      if (topupData==null) throw("eRror");
      let data = {
        "amount": topupData.amount,
        "user_id": topupData.userID,
      }
      console.log("data is ", data)
    
      const result = await network.post(routes.admin.topupWallet(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },

    async confirmWalletTopup(topupData) {
      if (topupData==null) throw("eRror");
      let data = {
        "transactionID": topupData.transactionID,
        "user_id": topupData.userID,
      }
    
      const result = await network.post(routes.admin.confirmWalletTopup(), data).then((response) => response).catch((error) => error)
      return this.apiResponseFormat(result);
    },





    apiResponseFormat(result) {
      if (result==null) throw('Api result cannot be null');
  
      let returnValue = {};
  
      if (!result.response && !result.status) {
        returnValue.success = false
        returnValue.data = internet_connection_message
        return returnValue
      }
      if (result.status) {
        switch (result.status) {
          case 200:
            returnValue.success = true
            returnValue.data = result.data.data
            break
        }
        return returnValue
      }
      if (!result.status) {
        switch (result.response.status) {
          case 400:
            returnValue.success = false
            returnValue.data = result.response.data.data
            break
          default:
            returnValue.success = false
            returnValue.data = problem_message
        }
        return returnValue
      }
    }
  },


  

}

export default api
