/* eslint-disable*/
const state = ()=> ({
    userData: window.localStorage.getItem("user_data"),
    adminProfile:null,
    adminList: [],
    customerList: null,
});

const getters = {

    retrieveUserToken(state, getters, rootState){
        let user_data = window.localStorage.getItem("user_data") ? JSON.parse(window.localStorage.getItem("user_data")) : null;
        const dd = user_data && user_data.token ? user_data.token.access_token: "";
        return user_data && user_data.token ? user_data.token.access_token: ""
    },

    retrieveUserData(state, getters, rootState){
        let user_data = window.localStorage.getItem("user_data") ? JSON.parse(window.localStorage.getItem("user_data")) : null;
        return user_data ? user_data : ""
    },

    retrieveAdminProfile(state, getters, rootState){
        return state.adminProfile;
    },

    retrieveAdminList(state, getters, rootState){
        let data = JSON.stringify(state.adminList);
        return JSON.parse(data);
    },

    retrieveCustomerList(state, getters, rootState){
        // let data = JSON.stringify(state.customerList);
        // return JSON.parse(data);
        return state.customerList;
    },
};

const mutations = {
    saveAuthenticatedUser(state, userData){
        userData = JSON.stringify(userData);
        window.localStorage.setItem("user_data", userData);
        state.userData = window.localStorage.getItem("user_data");
    },
    saveAdminList(state, adminList) {
        if (this.retrieveAdminList == null) state.adminList = adminList;
        else state.adminList = this.retrieveAdminList.concat(adminList);
    },
    clearAdminList(state) {
        state.adminList = [];
    },
    saveCustomerList(state, customerList) {
        // if (this.retrieveCustomerList == null) state.customerList = customerList;
        // else state.customerList = this.retrieveCustomerList.concat(customerList);
        state.customerList = customerList
    },
    clearCustomerList(state) {
        // state.customerList = [];
        state.customerList = null;
    },

    saveAdminProfile(state, profile) {
        state.adminProfile = profile
    },
    clearAdminProfile(state) {
        state.adminProfile = null;
    },
};

const actions = {
    saveAuthenticatedUser({commit}, userData){
        commit('saveAuthenticatedUser', userData)
    },
    saveAdminList({commit}, adminList) {
        commit('saveAdminList', adminList);
    },
    saveCustomerList({commit}, customerList) {
        commit('saveCustomerList', customerList);
    },
    saveAdminProfile({commit}, profile) {
        commit('saveAdminProfile', profile);
    },
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}