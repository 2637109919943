import { createRouter, createWebHistory } from 'vue-router'
import { repository } from '../presenter'
import AppSkeleton from '../views/AppSkeleton.vue'
// import Dashboard from '../views/Dashboard.vue'

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: ()=>import('../views/AuthBaseView'),
    children: [
      {
        path: 'confirm_registration',
        name: 'ConfirmRegistration',
        meta: { requiresAuth: false },
        component: () => import('../views/Auth/ConfirmRegistration'),
      },
      {
        path: 'login',
        name: 'Login',
        meta: { requiresAuth: false },
        component: () => import('../views/Auth/Login.vue'),
        beforeEnter: (to, from, next) => {
          if (repository.userAuthenticated()) next({ name: 'Dashboard' })
          else next()
        },
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        meta: { requiresAuth: false },
        component: () => import('../views/Auth/ForgotPassword'),
        beforeEnter: (to, from, next) => {
          if (repository.userAuthenticated()) next({ name: 'Dashboard' })
          else next()
        }
      },
      {
        path: 'confirm-password-reset',
        name: 'PasswordReset',
        meta: { requiresAuth: false },
        component: () => import('../views/Auth/PasswordReset'),
      },
    ]
  },
  

  {
    path: '/',
    name: 'AppSkeleton',
    component: AppSkeleton,
    children: [


      {
        path: '/profile',
        name: 'Profile',
        meta: { 
          requiresAuth: true,
          riderRoute: true,
        },
        component: () => import('../views/Auth/Profile')
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: { 
          requiresAuth: true,
          riderRoute: true,
        },
        beforeEnter: (to, from, next)=>{
          repository.clearAuthUserData();
          next({name: 'Login'})
        },
      },


      



      {
        path: '',
        name: 'Dashboard',
        meta: { 
          requiresAuth: true,
          riderRoute: true,
        },
        component: () => import('../views/Dashboard'),
      },






      {
        path: '/customers',
        name: 'CustomerManagment',
        meta: { requiresAuth: true },
        component: () => import('../views/UserManagement/Customers')
      },
      {
        path: '/customer/:user_id',
        name: 'CustomerView',
        meta: { requiresAuth: true },
        component: () => import('../views/UserManagement/CustomerView')
      },
      {
        path: '/admin/:user_id',
        name: 'AdminView',
        meta: { requiresAuth: true },
        component: () => import('../views/UserManagement/AdminView')
      },
      {
        path: '/admins',
        name: 'AdminManagement',
        meta: { requiresAuth: true },
        component: () => import('../views/UserManagement/Admins')
      },







      {
        path: '/:user_id/create-order',
        name: 'CreateOrder',
        meta: { requiresAuth: true },
        props: true,
        component: () => import('../views/OrderManagement/CreateOrder')
      },
      {
        path: '/:user_id/confirm-order',
        name: 'ConfirmOrder',
        meta: { requiresAuth: true },
        component: () => import('../views/OrderManagement/ConfirmOrder'),
        props: true,
      },
      {
        path: '/vieworders',
        name: 'ViewOrders',
        meta: { 
          requiresAuth: true,
          riderRoute: true,
        },
        component: () => import('../views/OrderManagement/ViewOrders')
      },
      {
        path: '/view-order/:orderID',
        name: 'ViewOrder',
        meta: { 
          requiresAuth: true,
          riderRoute: true,
        },
        component: () => import('../views/OrderManagement/ViewOrder')
      },














      {
        path: '/region-pricing/regions',
        name: 'Regions',
        component: () => import('../views/RegionAndPricing/Regions')
      },
      {
        path: '/region-pricing/nomenclatures',
        name: 'Nomenclatures',
        component: () => import('../views/RegionAndPricing/Nomenclatures')
      },
      {
        path: '/region-pricing/supported-weights',
        name: 'SupportedWeights',
        meta: { requiresAuth: true },
        component: () => import('../views/RegionAndPricing/SupportedWeights')
      },
      {
        path: '/region-pricing/price-list',
        name: 'PriceList',
        meta: { requiresAuth: true },
        component: () => import('../views/RegionAndPricing/PriceList')
      },










      {
        path: '/create-subscription',
        name: 'CreateSubscription',
        meta: { requiresAuth: true },
        component: () => import('../views/Subscription/NewSubscription')
      },
      {
        path: '/view-subscriptions',
        name: 'ViewSubscriptions',
        meta: { requiresAuth: true },
        component: () => import('../views/Subscription/Subscriptions')
      },
      {
        path: '/view-subscription/:subscription_id',
        name: 'ViewSubscription',
        meta: { requiresAuth: true },
        component: () => import('../views/Subscription/Subscription')
      },


      
    ]
  },
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !repository.userAuthenticated()) {
    next({ name: 'Login' })
    return
  }
  if (repository.userAuthenticated() && repository.adminIsRider() && !to.matched.some(record => record.meta.riderRoute)){
    next({name:'Dashboard'});
    return;
  }
  next()
})

export default router
