<template>
    <div class="wrapper">

        <header class="main-header">
            <!-- Sidebar toggle button-->
            <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
                <span class="sr-only">Toggle navigation</span>
            </a>
            <!-- Logo -->
            <router-link :to="{path: '/'}" class="logo">
                <!-- mini logo -->
                <b class="logo-mini">
                    <span class="light-logo"><img src="@/assets/images/ftd_logo_1.png" width="70" alt="logo"></span>
                    <span class="dark-logo"><img src="@/assets/images/ftd_logo_1.png" width="70" alt="logo"></span>
                </b>
                <!-- logo-->
                <!-- <span class="logo-lg">
                    <img src="@/assets/images/ftd_logo.png" alt="logo" class="light-logo">
                    <img src="@/assets/images/ftd_logo.png" alt="logo" class="dark-logo">
                </span> -->
            </router-link>
            <!-- Header Navbar -->
            <nav class="navbar navbar-static-top">
                <div class="ml-10 app-menu">
                    <ul class="header-megamenu nav">
                        
                        
                        
                    </ul> 
                </div>

                <div class="navbar-custom-menu">
                    <ul class="nav navbar-nav">
                        <!-- Notifications -->
                        <li class="dropdown notifications-menu">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                <i class="iconsmind-Bell"></i>
                            </a>
                            <ul v-if="notifications && notifications.length==0" class="dropdown-menu scale-up">
                                <li class="header">You have no notifications</li>
                            </ul>
                            <ul v-else class="dropdown-menu scale-up">
                                <li class="header">You have 7 notifications</li>
                                <li>
                                    <!-- inner menu: contains the actual data -->
                                    <ul class="menu inner-content-div">
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-users text-info"></i> Curabitur id eros quis nunc suscipit blandit.
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-warning text-warning"></i> Duis malesuada justo eu sapien elementum, in semper diam posuere.
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-users text-danger"></i> Donec at nisi sit amet tortor commodo porttitor pretium a erat.
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-shopping-cart text-success"></i> In gravida mauris et nisi
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-user text-primary"></i> Praesent eu lacus in libero dictum fermentum.
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-user text-danger"></i> Nunc fringilla lorem 
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <i class="fa fa-user text-danger"></i> Nullam euismod dolor ut quam interdum, at scelerisque ipsum imperdiet.
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="footer"><a href="#">View all</a></li>
                            </ul>
                        </li>
                        <!-- Control Sidebar Toggle Button -->
                        <li>
                            <a href="#" data-toggle="control-sidebar"><i class="iconsmind-Gears-2"></i></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>

        <!-- Left side column. contains the logo and sidebar -->
        <aside class="main-sidebar">
            <!-- sidebar-->
            <section class="sidebar">
                <div class="user-panel">
                    <div class="image">
                        <img src="@/assets/images/account1.png" class="avatar avatar-lg" alt="User Image">
                    </div>
                    <div v-if="profile" class="info">
                        <router-link :to="{path: '/profile'}" >{{ profile.first_name }} {{ profile.last_name }}</router-link>
                        <!-- <a class="dropdown-toggle" data-toggle="dropdown" href="#">{{ profile.first_name }} {{ profile.last_name }}</a> -->
                        <!-- <div class="dropdown-menu"> -->
                            <!-- <router-link :to="{path: '/profile'}" class="dropdown-item"><i class="ti-user"></i> Profile</router-link> -->
                            <!-- <a class="dropdown-item" href="#"><i class="ti-email"></i> Inbox</a> -->
                            <!-- <div class="dropdown-divider"></div> -->
                            <!-- <a class="dropdown-item" href="#"><i class="ti-settings"></i> Settings</a> -->
                        <!-- </div> -->
                    </div>
                    <ul class="list-inline user-setting">
                        <!-- <li><a href="#" data-toggle="tooltip" data-placement="top" title="Search"><i class="ti-search"></i></a></li> -->
                        <!-- <li><a href="#" data-toggle="tooltip" data-placement="top" title="Notification"><i class="ti-bell"></i></a></li> -->
                        <!-- <li><a href="pages/app/app-chat.html" data-toggle="tooltip" data-placement="top" title="Chat"><i class="ti-comment-alt"></i></a></li> -->
                        <li><router-link :to="{path: '/logout'}" data-toggle="tooltip" data-placement="top" title="Logout"><i class="ti-power-off"></i></router-link></li>
                    </ul>
                </div>

                <!-- sidebar menu-->
                <ul class="sidebar-menu" data-widget="tree">
                    <!-- Dashoboard -->
                    <li v-if="isRoleAllowedDashboard" v-bind:class="{'active': $route.name=='Dashboard'}">
                        <router-link :to="{path:'/'}">
                            <i class="mdi mdi-view-dashboard"></i>
                            <span>Dashboard</span>
                            <span class="pull-right-container">
                                <i class="fa fa-angle-right pull-right"></i>
                            </span>
                        </router-link>
                    </li>

                    <!-- User Management -->
                    <li v-if="isRoleAllowedUserManagement" class="treeview" v-bind:class="{'active': ['CustomerManagement', 'AdminManagement'].includes($route.name)}">
                        <a href="#">
                            <i class="mdi mdi-application"></i>
                            <span>User Management</span>
                            <span class="pull-right-container">
                                <i class="fa fa-angle-right pull-right"></i>
                            </span>
                        </a>
                        <ul class="treeview-menu">
                            <li v-bind:class="{'active': $route.name=='CustomerManagement'}"><router-link :to="{name: 'CustomerManagment'}"><i class="mdi mdi-resize-bottom-right"></i>Customer</router-link></li>
                            <li v-bind:class="{'active': $route.name=='AdminManagement'}"><router-link :to="{name: 'AdminManagement'}"><i class="mdi mdi-resize-bottom-right"></i>Admin</router-link></li>
                        </ul>
                    </li>

                    <!-- Order Management -->
                    <li v-if="isRoleAllowedOrderManagement" class="treeview"  v-bind:class="{'active': ['ViewOrders', 'ViewOrder'].includes($route.name)}">
                        <a href="#">
                            <i class="mdi mdi-application"></i>
                            <span>Order Management</span>
                            <span class="pull-right-container">
                                <i class="fa fa-angle-right pull-right"></i>
                            </span>
                        </a>
                        <ul class="treeview-menu">
                            <li v-bind:class="{'active': $route.name=='ViewOrders'}"><router-link :to="{name: 'ViewOrders'}"><i class="mdi mdi-resize-bottom-right"></i>View Orders</router-link></li>
                        </ul>
                    </li>

                    <!-- Regions and Pricing -->
                    <li v-if="isRoleAllowedRegionsAndPrices" class="treeview" v-bind:class="{'active': ['Regions', 'Nomenclatures', 'SupportedWeights', 'PriceList'].includes($route.name)}">
                        <a href="#">
                            <i class="mdi mdi-application"></i>
                            <span>Regions &amp; Pricing</span>
                            <span class="pull-right-container">
                                <i class="fa fa-angle-right pull-right"></i>
                            </span>
                        </a>
                        <ul class="treeview-menu">
                            <li v-bind:class="{'active': $route.name=='Regions'}"><router-link :to="{name: 'Regions'}"><i class="mdi mdi-resize-bottom-right"></i>Regions</router-link></li>
                            <li v-bind:class="{'active': $route.name=='Nomenclatures'}"><router-link :to="{name: 'Nomenclatures'}"><i class="mdi mdi-resize-bottom-right"></i>Nomenclatures</router-link></li>
                            <li v-bind:class="{'active': $route.name=='SupportedWeights'}"><router-link :to="{name: 'SupportedWeights'}"><i class="mdi mdi-resize-bottom-right"></i>Supported Weights</router-link></li>
                            <li v-bind:class="{'active': $route.name=='PriceList'}"><router-link :to="{name: 'PriceList'}"><i class="mdi mdi-resize-bottom-right"></i>Price List</router-link></li>
                        </ul>
                    </li>

                    <!-- Subscription Management -->
                    <li v-if="isRoleAllowedSubscriptionManagement" class="treeview" v-bind:class="{'active': ['CreateSubscription', 'ViewSubscriptions'].includes($route.name)}">
                        <a href="#">
                            <i class="mdi mdi-application"></i>
                            <span>Subscription<br>Management</span>
                            <span class="pull-right-container">
                                <i class="fa fa-angle-right pull-right"></i>
                            </span>
                        </a>
                        <ul class="treeview-menu">
                            <li v-bind:class="{'active': $route.name=='CreateSubscription'}"><router-link :to="{name: 'CreateSubscription'}"><i class="mdi mdi-resize-bottom-right"></i>Create Subscription</router-link></li>
                            <li v-bind:class="{'active': $route.name=='ViewSubscriptions'}"><router-link :to="{path: '/view-subscriptions'}"><i class="mdi mdi-resize-bottom-right"></i>View Subscriptions</router-link></li>
                        </ul>
                    </li>
                </ul>
            </section>
        </aside>

        <router-view/>

        <footer class="main-footer">
            <div class="pull-right d-none d-sm-inline-block">
                <ul class="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">FAQ</a>
                    </li>
                    <li class="nav-item">
                        <!-- <a class="nav-link" href="#">Purchase Now</a> -->
                    </li>
                </ul>
            </div>
            &copy; 2021 <router-link :to="{path: '/'}">FTD Logistics</router-link>. All Rights Reserved.
        </footer>

        <!-- Add the sidebar's background. This div must be placed immediately after the control sidebar -->
        <div class="control-sidebar-bg"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { repository } from '../presenter';
export default {
  name: 'AppSkeleton',
  computed: {
      ...mapGetters('user',{
          profile: 'retrieveAdminProfile',
      }),
      isRoleAllowedDashboard(){
        return true;
    },
    isRoleAllowedUserManagement(){
        if (repository.adminIsRider()){
            return false;
        }
        return true;
    },
    isRoleAllowedOrderManagement(){
        return true;
    },
    isRoleAllowedRegionsAndPrices(){
        if (repository.adminIsRider()){
            return false;
        }
        return true;
    },
    isRoleAllowedSubscriptionManagement(){
        if (repository.adminIsRider()){
            return false;
        }
        return true;
    }
  },
  mounted() {
    if (!this.profile || this.profile==null){
        this.loadAdminProfile();
    }
  },
  data() {
      return {
          publicPath: process.env.BASE_URL,
          notifications: [],
      }
  },
  methods: {
    loadAdminProfile(){
        console.log("loading admin profile in skeleton")
        repository.user.loadAdminProfile();
    },

    
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/bootstrap/dist/css/bootstrap.css');
@import url('/assets/styles/css/bootstrap-extend.css');
@import url('/assets/styles/css/master_style.css');
@import url('/assets/styles/css/skins/_all-skins.css');
</style>
