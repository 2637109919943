
const routes = {
  admin: {
    login: () => '/admin/login',
    logout: (userID)=> `/admin/logout?user_id=${userID}`,
    profile: ()=> `/admin/profile`,
    updateProfile: ()=> `/admin/update-profile`,
    adminRoles: () => '/admin/adminRoles',
    getPriceListForCity: () => '/admin/priceListForCity',
    admins: (perpage, page, data) => `/admin/admins?perpage=${perpage}&page=${page}&email=${data.email}&name=${data.name}&role_id=${data.role_id}`,
    searchActivityLogs: (data, perpage, page) => `/admin/admin-logs?from_date=${data.from_date}&to_date=${data.to_date}&perpage=${perpage}&page=${page}`,
    customers: (email, name, phone, perpage, page) => `/admin/customers?email=${email}&name=${name}&phone=${phone}&perpage=${perpage}&page=${page}`,
    requestExport: (email, name, phone) => `/admin/request-customer-export?email=${email}&name=${name}&phone=${phone}`,
    requestAdminExport: (email, name, role_id) => `/admin/request-admin-export?email=${email}&name=${name}&role_id=${role_id}`,
    requestOrderExport: (data) => `/admin/request-order-export?orderID=${data.orderID}&status=${data.status}&name=${data.name}&email=${data.email}&phone=${data.phone}&from_date=${data.from_date}&to_date=${data.to_date}`,
    customer: (userID) => `/admin/customer?user_id=${userID}`,
    customerApiAccess: (userID) => `/admin/customer-api-access?user_id=${userID}`,
    updateCustomerAPIAccess: () => `/admin/update-customer-api-access`,
    activateApi: (userID) => `/admin/activate-api-access?user_id=${userID}`,
    deactivateApi: (userID) => `/admin/deactivate-api-access?user_id=${userID}`,
    admin: (userID) => `/admin/admin?user_id=${userID}`,
    updateCustomerData: () => `/admin/update-customer`,
    updateAdminData: () => `/admin/update-admin`,
    updateRiderRegion: () => `/admin/update-rider-region`,
    newAdmin: () => '/admin/register',
    newCustomer: () => '/admin/register-customer',
    newOrder: () => '/admin/create-order',
    confirmOrder: () => '/admin/confirm-order',
    updateOrder: () => '/admin/update-order',
    updateBatchOrder: () => '/admin/update-batch-order',
    resetPassword: (email) => `/admin/reset-password?email=${email}`,
    completePasswordReset: () => '/admin/complete-password-reset',
    completeRegistration: () => '/admin/confirm-registration',
    loadOrderStatistics: (orderStatus) => `/admin/order-statistics?order_status=${orderStatus}`,
    loadCustomerOrderStatistics: (userID, orderStatus) => `/admin/customer-order-statistics?user_id=${userID}&order_status=${orderStatus}`,
    loadOrders: (data, perpage, page) => `/admin/orders?orderID=${data.orderID}&status=${data.status}&name=${data.name}&email=${data.email}&phone=${data.phone}&from_date=${data.from_date}&to_date=${data.to_date}&perpage=${perpage}&page=${page}`,
    loadOrder: (orderID) => `/admin/order?orderID=${orderID}`,
    loadSpecificCustomerOrder: (perpage, page) => `/admin/customer-order?perpage=${perpage}&page=${page}`,

    loadRiderOrderStatistics: (userID, orderStatus) => `/admin/rider-order-statistics?user_id=${userID}&order_status=${orderStatus}`,
    loadSpecificRiderOrder: (perpage, page) => `/admin/rider-order?perpage=${perpage}&page=${page}`,
    loadCustomerSubscriptions: (perpage, page) => `/admin/customer-subscriptions?perpage=${perpage}&page=${page}`,
    loadCustomerWalletHistory: (perpage, page) => `/admin/customer-wallets?perpage=${perpage}&page=${page}`,
    topupWallet: ()=> `/admin/fund-wallet`,
    confirmWalletTopup: ()=> `/admin/confirm-fund-wallet`,
    createNewCountry: ()=> `/admin/add-country`,
    createNewState: ()=> `/admin/add-state`,
    createNewCity: ()=> `/admin/add-city`,
    nomenclature: (bound, perpage, page)=> `/admin/nomenclatures?bound=${bound}&perpage=${perpage}&page=${page}`,
    interCityNomenclature: (perpage, page)=> `/admin/interCityNomenclatures?perpage=${perpage}&page=${page}`,
    interStateNomenclature: (perpage, page)=> `/admin/interStateNomenclatures?perpage=${perpage}&page=${page}`,
    interCountryNomenclature: (perpage, page)=> `/admin/interCountryNomenclatures?perpage=${perpage}&page=${page}`,
    createNewInterCityNomenclature: ()=> `/admin/addInterCityNomenclature`,
    updateInterCityNomenclature: ()=> `/admin/updateInterCityNomenclature`,
    createNewInterStateNomenclature: ()=> `/admin/addInterStateNomenclature`,
    updateInterStateNomenclature: ()=> `/admin/updateInterStateNomenclature`,
    createNewInterCountryNomenclature: ()=> `/admin/addInterCountryNomenclature`,
    updateInterCountryNomenclature: ()=> `/admin/updateInterCountryNomenclature`,
    newWeight: () => '/admin/add-weight',

    createNewInterCityPriceList: () => `/admin/addInterCityPriceList`,
    interCityPrices: (perpage, page)=> `/admin/interCityPriceList`,
    updateInterCityPriceList: () => `/admin/updateInterCityPriceList`,
    deleteInterCityPriceList: () => `/admin/deletePriceList`,

    createNewInterStatePriceList: () => `/admin/addInterStatePriceList`,
    interStatePrices: (perpage, page)=> `/admin/interStatePriceList`,
    updateInterStatePriceList: () => `/admin/updateInterStatePriceList`,
    deleteInterStatePriceList: () => `/admin/deletePriceList`,


    createNewInterCountryPriceList: () => `/admin/addInterCountryPriceList`,
    interCountryPrices: (perpage, page)=> `/admin/interCountryPriceList`,
    updateInterCountryPriceList: () => `/admin/updateInterCountryPriceList`,
    deleteInterCountryPriceList: () => `/admin/deletePriceList`,


    setCountryPickup: () => `/admin/set-country-pickup`,
    setCountryDelivery: () => `/admin/set-country-delivery`,
    setStatePickup: () => `/admin/set-state-pickup`,
    setStateDelivery: () => `/admin/set-state-delivery`,
    setCityPickup: () => `/admin/set-city-pickup`,
    setCityDelivery: () => `/admin/set-city-delivery`,

    deleteCountry: () => `/admin/delete-country`,
    deleteState: () => `/admin/delete-state`,
    deleteCity: () => `/admin/delete-city`,



    deleteWeight: () => `/admin/delete-weight`,
    deleteNomenclature: () => `/admin/delete-nomenclature`,


    uploadExcelFile: ()=> `/admin/upload-excel-file`,

    
    createSubscription: ()=> `/admin/create-subscription`,
    updateSubscription: ()=> `/admin/update-subscription`,
    subscriptions: () =>`/admin/subscriptions`,
    subscribeUser: () => `/admin/subscribe-customer`,
    renewCustomerSubscriptions: () => `/admin/renew-customer-subscription`,
    subscription: (subscriptionID)=> `/admin/subscription?sb=${subscriptionID}`,
    loadSubscribedCustomers: (perpage, page) => `/admin/subscribed-customers?perpage=${perpage}&page=${page}`,
    loadSubscriptionHistory: (perpage, page) => `/admin/subscription-history?perpage=${perpage}&page=${page}`,
    loadCustomerSubscriptionInformation: (userID) => `/admin/customer-subscription-information?user_id=${userID}`,
  },
  getCountries: (data, type='', nomenclatureID='', subscriptionID='') => `/getCountryData?for=${type}&query=${data.query}&nid=${nomenclatureID}&sid=${subscriptionID}`,
  getStates: (data, type='', nomenclatureID='', subscriptionID='') => `/getStateData?country_id=${data.countryID}&query=${data.query}&for=${type}&nid=${nomenclatureID}&sid=${subscriptionID}`,
  getCities: (data, type='', nomenclatureID='', subscriptionID='') => `/getCityData?state_id=${data.stateID}&query=${data.query}&for=${type}&nid=${nomenclatureID}&sid=${subscriptionID}`,
  getPriceEstimate: () => `/getPriceEstimate`,
  getWeights: (perpage, page)=> `/get-weights?perpage=${perpage}&page=${page}`,
}

export default routes
