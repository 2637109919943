/* eslint-disable*/
const state = ()=> ({
    customerSubscriptions: [],
    subscriptionList: [],
    currentSubscribedCustomersForASubscription: [],
    historyForASubscription: [],
});

const getters = {

    retrieveCustomerSubscriptions(state, getters, rootState){
        if (state.customerSubscriptions==null) return null;
        return state.customerSubscriptions;
    },

    retrieveSubscriptionList(state, getters, rootState){
        if (state.subscriptionList==null) return null;
        return state.subscriptionList;
    },

    retrieveCurrentSubscribedCustomersForASubscription(state, getters, rootState){
        if (state.currentSubscribedCustomersForASubscription==null) return null;
        return state.currentSubscribedCustomersForASubscription;
    },

    retrieveHistoryForASubscription(state, getters, rootState){
        if (state.historyForASubscription==null) return null;
        return state.historyForASubscription;
    },
};

const mutations = {

    saveCustomerSubscriptions(state, customerSubscriptions) {
        if (state.customerSubscriptions==null){
            state.customerSubscriptions = customerSubscriptions;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.customerSubscriptions));
            state.customerSubscriptions = formattedData.concat(customerSubscriptions);
        }
    },
    clearCustomerSubscriptions(state) {
        state.customerSubscriptions = [];
    },


    saveSubscriptionList(state, subscriptionList) {
        console.log("subscription list is ", subscriptionList)
        if (state.subscriptionList==null){
            state.subscriptionList = subscriptionList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.subscriptionList));
            state.subscriptionList = formattedData.concat(subscriptionList);
        }
    },
    clearSubscriptionList(state) {
        state.subscriptionList = [];
    },












    saveCurrentSubscribedCustomersForASubscription(state, currentSubscribedCustomersForASubscription) {
        if (state.currentSubscribedCustomersForASubscription==null){
            state.currentSubscribedCustomersForASubscription = currentSubscribedCustomersForASubscription;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.currentSubscribedCustomersForASubscription));
            state.currentSubscribedCustomersForASubscription = formattedData.concat(currentSubscribedCustomersForASubscription);
        }
    },
    clearCurrentSubscribedCustomersForASubscription(state) {
        state.currentSubscribedCustomersForASubscription = [];
    },



    saveHistoryForASubscription(state, historyForASubscription) {
        if (state.historyForASubscription==null){
            state.historyForASubscription = historyForASubscription;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.historyForASubscription));
            state.historyForASubscription = formattedData.concat(historyForASubscription);
        }
    },
    clearHistoryForASubscription(state) {
        state.historyForASubscription = [];
    },

    
};

const actions = {

    saveCustomerSubscriptions({commit}, customerSubscriptions) {
        commit('saveCustomerSubscriptions', customerSubscriptions);
    },
    saveSubscriptionList({commit}, subscriptionList) {
        commit('saveSubscriptionList', subscriptionList);
    },

    saveCurrentSubscribedCustomersForASubscription({commit}, currentSubscribedCustomersForASubscription) {
        commit('saveCurrentSubscribedCustomersForASubscription', currentSubscribedCustomersForASubscription);
    },

    saveHistoryForASubscription({commit}, historyForASubscription) {
        commit('saveHistoryForASubscription', historyForASubscription);
    },
    
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}