/* eslint-disable*/
const state = ()=> ({
    countryList: [],
    stateList: [],
    cityList: [],
    regions: [],
    nomenclatureList: [],
    interCityNomenclatureList: [],
    interStateNomenclatureList: [],
    interCountryNomenclatureList: [],
    weightList: [],
    interCityPriceList: [],
    interStatePriceList: [],
    interCountryPriceList: [],
});

const getters = {

    retrieveCountryList(state, getters, rootState){
        if (state.countryList==null) return null;
        return state.countryList;
    },
    retrieveStateList(state, getters, rootState){
        if (state.stateList==null) return null;
        return state.stateList;
    },
    retrieveCityList(state, getters, rootState){
        if (state.cityList==null) return null;
        return state.cityList;
    },

    retrieveNomenclatureList(state, getters, rootState){
        let data = JSON.stringify(state.nomenclatureList);
        return JSON.parse(data);
    },

    retrieveRegions(state, getters, rootState){
        let data = JSON.stringify(state.regions);
        return JSON.parse(data);
    },

    retrieveInterCityNomenclatures(state, getters, rootState){
        if (state.interCityNomenclatureList==null) return null;
        return state.interCityNomenclatureList;
    },
    retrieveInterStateNomenclatures(state, getters, rootState){
        if (state.interStateNomenclatureList==null) return null;
        return state.interStateNomenclatureList;
    },
    retrieveInterCountryNomenclatures(state, getters, rootState){
        if (state.interCountryNomenclatureList==null) return null;
        return state.interCountryNomenclatureList;
    },

    retrieveWeights(state, getters, rootState){
        if (state.weightList==null) return null;
        return state.weightList;
    },
    retrieveInterCityPriceList(state, getters, rootState){
        if (state.interCityPriceList==null) return null;
        return state.interCityPriceList;
    },
    retrieveInterStatePriceList(state, getters, rootState){
        if (state.interStatePriceList==null) return null;
        return state.interStatePriceList;
    },
    retrieveInterCountryPriceList(state, getters, rootState){
        if (state.interCountryPriceList==null) return null;
        return state.interCountryPriceList;
    },
};

const mutations = {
    saveCountryList(state, countryList) {
        if (state.countryList==null){
            state.countryList = countryList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.countryList));
            state.countryList = formattedData.concat(countryList);
        }
    },
    saveStateList(state, stateList) {
        if (state.stateList==null){
            state.stateList = stateList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.stateList));
            state.stateList = formattedData.concat(stateList);
        }
    },
    saveCityList(state, cityList) {
        if (state.cityList==null){
            state.cityList = cityList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.cityList));
            state.cityList = formattedData.concat(cityList);
        }
    },

    saveNomenclatureList(state, nomenclatureList) {
        if (state.nomenclatureList==null){
            state.nomenclatureList = nomenclatureList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.nomenclatureList));
            state.nomenclatureList = formattedData.concat(nomenclatureList);
        }
    },
    clearNomenclatureList(state) {
        state.nomenclatureList = [];
    },

    saveRegions(state, regionData){
        if (this.retrieveRegions==null) state.regions = regionData;
        else state.regions = this.retrieveRegions.concat(regionData);
    },


    saveInterCityNomenclatureList(state, interCityNomenclatureList) {
        if (state.interCityNomenclatureList==null){
            state.interCityNomenclatureList = interCityNomenclatureList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.interCityNomenclatureList));
            state.interCityNomenclatureList = formattedData.concat(interCityNomenclatureList);
        }
    },
    clearInterCityNomenclatureList(state) {
        state.interCityNomenclatureList = [];
    },
    saveInterStateNomenclatureList(state, interStateNomenclatureList) {
        if (state.interStateNomenclatureList==null){
            state.interStateNomenclatureList = interStateNomenclatureList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.interStateNomenclatureList));
            state.interStateNomenclatureList = formattedData.concat(interStateNomenclatureList);
        }
    },
    clearInterStateNomenclatureList(state) {
        state.interStateNomenclatureList = [];
    },
    saveInterCountryNomenclatureList(state, interCountryNomenclatureList) {
        if (state.interCountryNomenclatureList==null){
            state.interCountryNomenclatureList = interCountryNomenclatureList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.interCountryNomenclatureList));
            state.interCountryNomenclatureList = formattedData.concat(interCountryNomenclatureList);
        }
    },
    clearInterCountryNomenclatureList(state) {
        state.interCountryNomenclatureList = [];
    },


    saveWeightList(state, weightList) {
        if (state.weightList==null){
            state.weightList = weightList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.weightList));
            state.weightList = formattedData.concat(weightList);
        }
    },

    clearWeightList(state) {
        state.weightList = [];
    },
    
    saveInterCityPriceList(state, interCityPriceList) {
        if (state.interCityPriceList==null){
            state.interCityPriceList = interCityPriceList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.interCityPriceList));
            state.interCityPriceList = formattedData.concat(interCityPriceList);
        }
    },
    clearInterCityPriceList(state) {
        state.interCityPriceList = [];
    },



    saveInterStatePriceList(state, interStatePriceList) {
        if (state.interStatePriceList==null){
            state.interStatePriceList = interStatePriceList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.interStatePriceList));
            state.interStatePriceList = formattedData.concat(interStatePriceList);
        }
    },
    clearInterStatePriceList(state) {
        state.interStatePriceList = [];
    },

    saveInterCountryPriceList(state, interCountryPriceList) {
        if (state.interCountryPriceList==null){
            state.interCountryPriceList = interCountryPriceList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.interCountryPriceList));
            state.interCountryPriceList = formattedData.concat(interCountryPriceList);
        }
    },
    clearInterCountryPriceList(state) {
        state.interCountryPriceList = [];
    },

    clearCountryList(state) {
        state.countryList = [];
    },
    clearStateList(state) {
        state.stateList = [];
    },
    clearCityList(state) {
        state.cityList = [];
    },
};

const actions = {
    saveCountries({commit}, countryData){
        commit('saveCountries', countryData)
    },
    saveNomenclatureList({commit}, nomenclatureList) {
        commit('saveNomenclatureList', nomenclatureList);
    },
    saveRegions({commit}, regionData){
        commit('saveRegions', regionData)
    },
    saveInterCityNomenclatureList({commit}, interCityNomenclatureList) {
        commit('saveInterCityNomenclatureList', interCityNomenclatureList);
    },
    saveInterStateNomenclatureList({commit}, interStateNomenclatureData){
        commit('saveInterStateNomenclatureList', interStateNomenclatureData)
    },
    saveInterCountryNomenclatureList({commit}, interCountryNomenclatureList) {
        commit('saveInterCountryNomenclatureList', interCountryNomenclatureList);
    },
    saveWeightList({commit}, weightList) {
        commit('saveWeightList', weightList);
    },
    saveInterCityPriceList({commit}, interCityPriceList) {
        commit('saveInterCityPriceList', interCityPriceList);
    },
    saveInterStatePriceList({commit}, interStatePriceList) {
        commit('saveInterStatePriceList', interStatePriceList);
    },
    saveInterCountryPriceList({commit}, interCountryPriceList) {
        commit('saveInterCountryPriceList', interCountryPriceList);
    },
    saveCountryList({commit}, countryList){
        commit('saveCountryList', countryList)
    },
    saveStateList({commit}, stateList){
        commit('saveStateList', stateList)
    },
    saveCityList({commit}, cityList){
        commit('saveCityList', cityList)
    },
    
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}