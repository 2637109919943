/* eslint-disable*/
const state = ()=> ({
    orderStatistics: [],
    orderList: [],
    customerOrderStatistics: [],
    customerOrderList: [],
    riderOrderStatistics: [],
    riderOrderList: [],
});

const getters = {

    retrieveOrderStatistics(state, getters, rootState){
        if (state.orderStatistics==null) return null;
        return state.orderStatistics;
    },
    retrieveCustomerOrderStatistics(state, getters, rootState){
        if (state.customerOrderStatistics==null) return null;
        return state.customerOrderStatistics;
    },

    retrieveOrderList(state, getters, rootState){
        if (state.orderList==null) return null;
        return state.orderList;
    },
    retrieveCustomerOrderList(state, getters, rootState){
        if (state.customerOrderList==null) return null;
        return state.customerOrderList;
    },


    retrieveRiderOrderStatistics(state, getters, rootState){
        if (state.riderOrderStatistics==null) return null;
        return state.riderOrderStatistics;
    },
    retrieveRiderOrderList(state, getters, rootState){
        if (state.riderOrderList==null) return null;
        return state.riderOrderList;
    },
};

const mutations = {
    
    saveOrderStatistics(state, orderStatistics) {
        // state.orderStatistics = orderStatistics;

        if (state.orderStatistics==null){
            state.orderStatistics = orderStatistics;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.orderStatistics));
            state.orderStatistics = formattedData.concat(orderStatistics);
        }
    },
    saveCustomerOrderStatistics(state, customerOrderStatistics) {
        // state.customerOrderStatistics = customerOrderStatistics;

        if (state.customerOrderStatistics==null){
            state.customerOrderStatistics = customerOrderStatistics;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.customerOrderStatistics));
            state.customerOrderStatistics = formattedData.concat(customerOrderStatistics);
        }
    },
    saveOrderList(state, orderList) {
        // if (this.retrieveOrderList == null) state.orderList = orderList;
        // else state.orderList = this.retrieveOrderList.concat(orderList);


        if (state.orderList==null){
            state.orderList = orderList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.orderList));
            state.orderList = formattedData.concat(orderList);
        }
    },
    saveCustomerOrderList(state, customerOrderList) {
        // if (this.retrieveCustomerOrderList == null) state.customerOrderList = customerOrderList;
        // else state.customerOrderList = this.retrieveCustomerOrderList.concat(customerOrderList);


        if (state.customerOrderList==null){
            state.customerOrderList = customerOrderList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.customerOrderList));
            state.customerOrderList = formattedData.concat(customerOrderList);
        }
    },


    saveRiderOrderStatistics(state, riderOrderStatistics) {
        if (state.riderOrderStatistics==null){
            state.riderOrderStatistics = riderOrderStatistics;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.riderOrderStatistics));
            state.riderOrderStatistics = formattedData.concat(riderOrderStatistics);
        }
    },

    saveRiderOrderList(state, riderOrderList) {
        if (state.riderOrderList==null){
            state.riderOrderList = riderOrderList;
        }
        else{
            let formattedData = JSON.parse(JSON.stringify(state.riderOrderList));
            state.riderOrderList = formattedData.concat(riderOrderList);
        }
    },

    clearOrderStatistics(state) {
        state.orderStatistics = [];
    },
    clearOrderList(state) {
        state.orderList = [];
    },
    clearCustomerOrderStatistics(state) {
        state.customerOrderStatistics = [];
    },
    clearCustomerOrderList(state) {
        state.customerOrderList = [];
    },
    clearRiderOrderStatistics(state) {
        state.riderOrderStatistics = [];
    },
    clearRiderOrderList(state) {
        state.riderOrderList = [];
    },
};

const actions = {
    saveOrderStatistics({commit}, orderStatistics){
        commit('saveOrderStatistics', orderStatistics)
    },
    saveCustomerOrderStatistics({commit}, customerOrderStatistics){
        commit('saveCustomerOrderStatistics', customerOrderStatistics)
    },
    saveOrderList({commit}, orderList) {
        commit('saveOrderList', orderList);
    },
    saveCustomerOrderList({commit}, customerOrderList) {
        commit('saveCustomerOrderList', customerOrderList);
    },

    saveRiderOrderStatistics({commit}, riderOrderStatistics){
        commit('saveRiderOrderStatistics', riderOrderStatistics)
    },
    saveRiderOrderList({commit}, riderOrderList) {
        commit('saveRiderOrderList', riderOrderList);
    },
    
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}