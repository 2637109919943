/* eslint-disable*/
const state = ()=> ({
    adminRoles: null,
});

const getters = {

    retrieveAdminRoles(state, getters, rootState){
        let data = JSON.stringify(state.adminRoles);
        return JSON.parse(data);
    }
};

const mutations = {
    saveAdminRoles(state, roleData){
        
        state.adminRoles = roleData;
        let data = JSON.stringify(state.adminRoles);
        state.adminRoles = JSON.parse(data);
    }
};

const actions = {
    saveAdminRoles({commit}, roleData){
        commit('saveAdminRoles', roleData)
    }
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}