import axios from 'axios'
import datastore from '../datastore'

const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_LIVE_API_URL : process.env.VUE_APP_DEVELOPMENT_API_URL

const headers = {
  Authorization: `Bearer ${datastore.getters['user/retrieveUserToken']}`,
  'content-type': 'multipart/form-data'
}

const timeout = 60000

const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  timeout: timeout
})

export default instance
